import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import { SEO } from "../components/seo"
import ReactMarkdown from "react-markdown"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

// Add Page Head component and pass in SEO variables from GraphQL
export const Head = ({ data }) => {
  const title = data.strapiProduct.productName
  const description = data.strapiProduct.shortDesc
  const shareImage = getImage(data.strapiProduct.productHeroImage.localFile)
  return (
    <SEO title={title} description={description} iamge={shareImage} />
  )
}


// Product page data for each product
export default function ProductDetails( {data} ) {
  const product = data.strapiProduct  
  const productmedia = data.strapiProductMedia
  const headline = product.productName

   // text 

  const Teaser = product.teaser.data.teaser
  const Credibility = product.credibility.data.credibility
  const Problem = product.problem.data.problem
  const Solution = product.solution.data.solution
  const Bonus = product.bonus.data.bonus
  const Close = product.close.data.close

  // images
  const heroImage = getImage(product.productHeroImage.localFile)
  const credImage = getImage(productmedia.credibility.localFile)
  const solImage = getImage(productmedia.solution.localFile)
  const bonImage = getImage(productmedia.bonus.localFile)
  const cloImage = getImage(productmedia.close.localFile)

  return(
    <Layout>
      <div>
      <section className="bg-white dark:bg-gray-900">
        <div className="grid max-w-screen-xl px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12">
          <div className="mr-auto place-self-center lg:col-span-7">
            <h1 className="max-w-2xl mb-4 text-4xl font-extrabold tracking-tight leading-none md:text-5xl xl:text-6xl text-red-400 dark:text-white">{headline}</h1>
            <div className="max-w-2xl space-y-3 mb-6 font-light lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400"><ReactMarkdown>{Teaser}</ReactMarkdown></div>
            <a href="#cta" className="inline-flex items-center justify-center px-5 py-3 mr-3 text-base font-medium text-center text-white rounded-lg bg-red-400 hover:bg-slate-900 focus:ring-4 focus:ring-primary-300 dark:focus:ring-primary-900">
                Buy now
          </a>
        </div>
          <div className="py-6"><GatsbyImage className="w-96 rounded-3xl shadow-2xl" image={heroImage} alt={headline} />
        </div>                
        </div>
        </section>
        <section className="py-20 bg-white">
            <div className="flex flex-col px-8 mx-auto space-y-12 max-w-7xl xl:px-12">
              <div className="relative">
                <h2 className="w-full text-3xl text-red-400 font-bold text-center sm:text-4xl md:text-5xl"> Who Am I?</h2>
                <div className="w-full py-8 mx-auto -mt-2 text-xl text-center intro sm:max-w-3xl">My name is Steve, and before I continue telling you why you need this work I should explain.</div>
              </div>
            </div>
        </section>
        <section className="bg-white dark:bg-gray-900">
        <div className="grid max-w-screen-xl px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12">
          <div className="mr-auto place-self-center lg:col-span-7">
             <div className="max-w-2xl mb-6 space-y-3 lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400"><ReactMarkdown>{Credibility}</ReactMarkdown></div>
        </div>
          <div className="py-6"><GatsbyImage className="w-96 rounded-3xl shadow-2xl" image={credImage} alt="Credibility image" />
        </div>                
        </div>
        </section>
        <section className="py-6 bg-white">
            <div className="flex flex-col px-8 mx-auto space-y-12 max-w-7xl xl:px-12">
              <div className="relative">
                <h2 className="w-full text-3xl font-bold text-center text-red-400 sm:text-4xl md:text-5xl">The Problem</h2>
                </div>
            </div>
        </section>
        <section className="bg-white dark:bg-gray-900">
        <div className="grid max-w-screen-xl px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16">
          <div className="min-w-full mb-6 space-y-3 lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400"><ReactMarkdown>{Problem}</ReactMarkdown></div>
        </div>
        </section>
        <section className="py-6 bg-white">
            <div className="flex flex-col px-8 mx-auto space-y-12 max-w-7xl xl:px-12">
              <div className="relative">
                <h2 className="w-full text-3xl font-bold text-center text-red-400 sm:text-4xl md:text-5xl">The Solution</h2>
                </div>
            </div>
        </section>
        <section className="bg-white dark:bg-gray-900">
        <div className="grid max-w-screen-xl px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12">
          <div className="mr-auto place-self-center lg:col-span-7">
             <div className="max-w-2xl mb-6 space-y-3 lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400"><ReactMarkdown>{Solution}</ReactMarkdown></div>
        </div>
          <div className="py-6"><GatsbyImage className="w-96 rounded-3xl shadow-2xl" image={solImage} alt="Solution image" />
        </div>                
        </div>
        </section>
        <section className="py-6 bg-white">
            <div className="flex flex-col px-8 mx-auto space-y-12 max-w-7xl xl:px-12">
              <div className="relative">
                <h2 className="w-full text-3xl font-bold text-center text-red-400 sm:text-4xl md:text-5xl">But that's not all</h2>
                </div>
            </div>
        </section>
        <section className="bg-white dark:bg-gray-900">
        <div className="grid max-w-screen-xl px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12">
          <div className="mr-auto place-self-center lg:col-span-7">
             <div className="max-w-2xl mb-6 space-y-3 lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400"><ReactMarkdown>{Bonus}</ReactMarkdown></div>
        </div>
          <div className="py-6"><GatsbyImage className="w-96 rounded-3xl shadow-2xl" image={bonImage} alt="Bonus image"/>
        </div>                
        </div>
        </section>
        <section className="py-20 bg-white">
            <div className="flex flex-col px-8 mx-auto space-y-12 max-w-7xl xl:px-12">
              <div className="relative">
                <h2 className="w-full text-3xl font-bold text-center text-red-400 sm:text-4xl md:text-5xl">What are you waiting for?</h2>
                </div>
            </div>
        </section>
        <section className="bg-white dark:bg-gray-900">
        <div className="grid max-w-screen-xl px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12">
          <div className="mr-auto place-self-center lg:col-span-7">
             <div className="max-w-2xl mb-6 space-y-3 lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400"><ReactMarkdown>{Close}</ReactMarkdown></div>
        </div>
          <div className="py-6"><GatsbyImage className="w-96 rounded-3xl shadow-2xl" image={cloImage} alt="Guranteed 100%" />
        </div>                
        </div>
        </section>
      </div>
      <section className="container text-center max-w-5xl py-8">
        <div className="product">
          <div className="description py-6">
          <h3 className="font-bold text-center text-red-400 sm:text-4xl md:text-5xl">Get Both Books Today!</h3>
          <h5 className="mb-6 p-2 text-center text-red-400 lg:mb-8 md:text-2lg lg:text-2xl dark:text-gray-400">For Just €37.00...</h5>
          </div>
        </div>
        <button id="cta" type="submit" className="bg-red-400 text-white font-bold py-2 px-4 rounded focus:ring focus:ring-blue-300 hover:bg-slate-900">
        <a href="https://buy.stripe.com/dR6eWt4807omamAfYY">Checkout and buy now</a>
      </button>
      </section>
    </Layout>
  )
}

// Get the data from strapi single product data related to slug
export const query = graphql`
query($slug: String) {
  strapiProduct(slug: {eq: $slug}) {
    productName
    shortDesc
    productHeroImage {
      localFile {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
    teaser {
      data {
        teaser
      }
    }
    credibility {
      data {
        credibility
      }
    }
    problem {
      data {
        problem
      }
    }
    solution {
      data {
        solution
      }
    }
    bonus {
      data {
        bonus
      }
    }
    close {
      data {
        close
      }
    }
  }
  strapiProductMedia {
    credibility {
      localFile {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
    problem {
      localFile {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
    solution {
      localFile {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
    bonus {
      localFile {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
    close {
      localFile {
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  }
}
`
